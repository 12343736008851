<template>
  <div class="mt-3">
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(item, i) in vendor" :key="i">
        <v-expansion-panel-header>
          {{ item.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="flex">
            <div class="flex flex-column">
              <span>Name : {{ item.name }}</span>
              <span>Address : {{ item.address }}</span>
              <span>Address 2 : {{ item.address2 }}</span>
              <span>Postcode : {{ item.postcode }}</span>
              <span>Phone Number : {{ item.phone }}</span>
            </div>
            <div class="flex flex-column">
              <span>Bank type : {{ item.bank_type }}</span>
              <span>Bank Account no : {{ item.bank_acc }}</span>
            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="info"> Edit </v-btn>
            <v-btn text color="error"> Remove </v-btn>
            <v-btn text color="success"> Save </v-btn>
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="vendor.length == 0">No vendors created</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tags",
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      vendor: "getVendor",
    }),
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchVendor");
  },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
